@import "../../scss/variables/_index.scss";

.input__wrapper {
  margin-bottom: 33px;
}

.input__container {
  margin-bottom: 8px;
}

.input {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  color: $gray0;
  background: transparent;
  border: none;
  border-bottom: 1px solid $gray30;
  padding: 4px 8px;
  outline: none;

  &:focus {
    border-bottom-color: $gray0;
  }

  @media(min-width: $size-tablet-inner) {
    font-size: 16px;
    line-height: 26px;
  }
}

.textarea {
  height: 100px;
  resize: none;
}

.input--error {
  border: 1px solid $orange;

  &:focus {
    border-bottom-color: $orange;
  }

  &::placeholder {
    color: $orange;
  }
}

.input-value {
  border-bottom-color: $gray0;
}

.input-full {
  width: 100%;
}

.input__message {
  font-family: $font-noto;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: $orange;
}

// Select
// ======================================
.select__container {
  display: flex;
  margin-bottom: 24px;
}

.select__container--inner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @media(min-width: $size-tablet-inner) {
    width: 400px;
  }
}

.select__icon {
  position: absolute;
  right: 16px;
}