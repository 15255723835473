@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  align-items: center;
  background: $gray20;
}

.prev__container {
  display: flex;
  flex: 1;
}

.prev__link {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 0;
  padding-left: 20px;

  &:hover {
    opacity: 0.7;
  }

  > .navbar__label {
    margin-right: 24px;
  }

  @media(min-width: $size-tablet-inner) {
    flex-direction: row;
    padding: 48.5px 0;
    padding-left: 36px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 47px 0;
    padding-left: 67px;
  }
}

.next__container {
  display: flex;
  flex: 1;
}

.next__link {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 24px 0;
  padding-right: 20px;

  &:hover {
    opacity: 0.5;
  }

  > .navbar__label {
    margin-left: 24px;
  }

  @media(min-width: $size-tablet-inner) {
    flex-direction: row;
    padding: 48.5px 0;
    padding-right: 36px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 47px 0;
    padding-right: 67px;
  }
}

.navbar__label {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  color: $black;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.navbar__title {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: $black;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.navbar__logo {
  max-height: 30px;
  max-width: 80px;
  margin-top: 8px;

  @media(min-width: $size-tablet-inner) {
    max-height: 34px;
    max-width: 145px;
    margin-top: 0;
    align-self: center;
  }
}