@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  justify-content: center;
}

.container__inner {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: $size-desktop;
  background: $orange;
  padding: 64px 0;
}

.section {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.font__label {
  font-family: $font-noto;
  font-size: 32px;
  line-height: 32px * 1.5;
}

.font__label--size {
  font-family: $font-noto;
}

.font__label-100 {
  font-weight: 100;
}

.font__label-200 {
  font-weight: 200;
}

.font__label-300 {
  font-weight: 300;
}

.font__label-400 {
  font-weight: 400;
}

.font__label-500 {
  font-weight: 500;
}

.font__label-600 {
  font-weight: 600;
}

.font__label-700 {
  font-weight: 700;
}

.font__label-800 {
  font-weight: 800;
}

.font__size-10 {
  font-size: 10px;
  line-height: 10px * 1.5;
}

.font__size-12 {
  font-size: 12px;
  line-height: 12px * 1.5;
}

.font__size-14 {
  font-size: 14px;
  line-height: 14px * 1.5;
}

.font__size-16 {
  font-size: 16px;
  line-height: 16px * 1.5;
}

.font__size-18 {
  font-size: 18px;
  line-height: 18px * 1.5;
}

.font__size-19 {
  font-size: 19px;
  line-height: 19px * 1.5;
}

.font__size-20 {
  font-size: 20px;
  line-height: 20px * 1.5;
}

.font__size-24 {
  font-size: 24px;
  line-height: 24px * 1.5;
}

.font__size-27 {
  font-size: 27px;
  line-height: 27px * 1.5;
}

.font__size-32 {
  font-size: 32px;
  line-height: 32px * 1.5;
}

.font__size-36 {
  font-size: 36px;
  line-height: 36px * 1.5;
}

.font__size-42 {
  font-size: 42px;
  line-height: 42px * 1.5;
}

.font__size-48 {
  font-size: 48px;
  line-height: 48px * 1.5;
}

.font__size-48 {
  font-size: 48px;
  line-height: 48px * 1.5;
}

.font__size-56 {
  font-size: 56px;
  line-height: 56px * 1.5;
}

.font__size-64 {
  font-size: 64px;
  line-height: 64px * 1.5;
}

.font__size-72 {
  font-size: 72px;
  line-height: 72px * 1.5;
}