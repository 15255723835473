@import "../../scss/variables/_index.scss";

.header {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  height: 64px;
  background: $gray-gradient;
  z-index: 10;

  @media(min-width: $size-tablet-inner) {
    height: 72px;
  }

  @media(min-width: $size-tablet-outer) {
    height: 130px;
  }
}

.header-transparent {
  background: transparent;
}

.header__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: $size-desktop;
  padding: 16px 20px;
  overflow: hidden;

  @media(min-width: $size-tablet-inner) {
    padding: 16px 36px;
  }

  @media(min-width: $size-tablet-outer) {
    padding: 40px 32px;
  }
}

.header__container--mobile {
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media(min-width: $size-outer-semi-tablet) {
    display: none;
  }
}

// Main Logo
// =====================================
.header__container--logo {
  flex: 1;
}

.header--logo {
  width: 106px;

  @media(min-width: $size-tablet-inner) {
    width: 132px;
  }

  @media(min-width: $size-tablet-outer) {
    width: 166px;
  }
}

// Menu
// =====================================
.header__container--link {
  display: none;
  flex-grow: 2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: $size-outer-semi-tablet) {
    display: flex;
  }
}

.header__container--inner {
  display: flex;
  flex-direction: row;
}

.header__container--link-hide {
  display: none;
}

.header__link {
  font-family: $font-noto;
  font-size: 19px;
  line-height: 26px;
  color: $gray0;
  text-decoration: none; 

  & + & {
    margin-left: 32px;
  }

  @media(min-width: $size-mid-desktop) {
    & + & {
      margin-left: 61px;
    }
  }
}

.header__link-black {
  color: $black;
}

.header__container--language {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 40px;

  @media(min-width: $size-mid-desktop) {
    margin-left: 60px;
  }
}

.language__link {
  font-family: $font-noto;
  font-size: 19px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: $gray0;
  cursor:pointer;
}

.language-active {
  color: $orange;
}

.language__separator {
  width: 1px;
  height: 20px;
  background: $gray0;
  margin: 0 8px;
}

// Menu Icon
// =====================================
.header__container--icon {
  margin-left: 24px;
  padding-left: 24px;

  @media(min-width: $size-mid-desktop) {
    margin-left: 58px;
    padding-left: 24px;
  }
}

.header__mobile--icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 42px;

  @media(min-width: $size-outer-semi-tablet) {
    display: none;
  }
}

.header__icon {
  font-size: 24px;
  line-height: 0.5;
  cursor: pointer;
  @media(min-width: $size-tablet-inner) {
    font-size: 32px;
  }
}

// Search Bar
// =====================================
.header__container--search {
  display: none;
  flex-grow: 2;
  justify-content: flex-end;
  align-items: center;
}

.header__container--search-open {
  display: none;

  @media(min-width: $size-semi-tablet) {
    display: flex;
  }
}

.header__search {
  position: relative;
}

.header__search--input {
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  color: $gray0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $gray0;
  padding: 8px 0;
  width: 240px;
  outline: none;

  &::-webkit-input-placeholder { /* Edge */
    color: $gray0;
  }

  &::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $gray0;
  }
  
  &::placeholder {
    color: $gray0;
  }
}

.header__search--button {
  position: absolute;
  right: 0;
  border: none;
  background-color: transparent;
  outline: none;
}

.header__container--close {
  padding-left: 28px;
}

.header__icon--close {
  font-size: 28px;
}