@import "../../scss/variables/_index.scss";

.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  background: $gray50;
  padding: 24px 0;
  padding-top: 104px;
  @media(min-width: $size-tablet-inner) {
    padding-top: 192px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 40px 0;
    padding-top: 310px;
  }
}

.container__inner {
  display: flex;
  flex: 1;
  max-width: $size-desktop;
  padding: 0 20px;
  z-index:1;
  @media(min-width: $size-tablet-inner) {
    padding: 0 35px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 0 67px;
  }
}

.title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: $gray0;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 70px;
    line-height: 1;
  }
}