@import "../../scss/variables/_index.scss";

.modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10;
  padding: 0 20px;

  @media(min-width: $size-tablet) {
    padding: 0;
  }
}

.modal-open {
  display: flex;
}

// Content
// ==========================================
.modal__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(min-width: $size-tablet) {
    width: 560px;
  }

  @media(min-width: $size-semi-tablet) {
    width: 640px;
  }
}

.modal__header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: $black;
  padding: 20px 16px;
}

.modal__title {
  font-family: $font-gabriela;
  font-size: 36px;
  line-height: 42px;
  color: $gray0;
}

.modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 24px;

  &:hover {
    cursor: pointer;
  }
}

.modal__icon {
  font-size: 16px;
}

.modal__content {
  padding: 16px;
  padding-top: 4px;
  background: $gray0;
  height: 60vh;
  overflow-y: scroll;
}
.city__name {
  
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: #a9a9a9;
  margin-bottom: 12px;
  margin-top: 12px;

  @media(min-width: $size-tablet) {
    font-size: 36px;
    line-height: 42px;
  }
}

.location {
  & + & {
    margin-top: 12px;
  }
}

.location__name {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.03em;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.location__text {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  letter-spacing: 0.03em;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.location__separator {
  font-size: 12px;
  margin: 0 4px;
}