@import "../../scss/variables/_index.scss";

.content__image {
  width: 100%;
  height: auto;
}

.content__container--side {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media(min-width: $size-tablet-inner) {
    flex-direction: row;
  }
}

// Single Picture Content
// ================================
.content__container--image {
  width: 100%;
  margin-bottom: 24px;
}

// Double Picture Content
// ================================
.container__image {
  flex: 1 0 100%;
  align-self: center;

  @media(min-width: $size-tablet-inner) {
    flex: 1 0 50%;
  }
}

.container__image--left {
  padding-right: 0;
  margin-bottom: 24px;

  @media(min-width: $size-tablet-inner) {
    padding-right: 16px;
    margin-bottom: 0;
  }

  @media(min-width: $size-desktop-inner) {
    padding-right: 40px;
  }
}

.container__image--right {
  padding-left: 0;

  @media(min-width: $size-tablet-inner) {
    padding-left: 16px;
  }
  @media(min-width: $size-desktop-inner) {
    padding-left: 40px;
  }
}

// Picture and Text Content
// ================================
.container__text {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  padding: 0;

  @media(min-width: $size-tablet-inner) {
    padding: 0 24px;
  }

  @media(min-width: $size-tablet) {
    padding: 0 55.5px;
    padding-top: 40px;
  }

  @media(min-width: $size-desktop-inner) {
    padding: 0 108px;
    padding-top: 80px;
  }
}

.content__line {
  width: 64px;
  height: 4px;
  background: linear-gradient(90.11deg, #E95314 -4.59%, #F1A055 100%);
  margin-bottom: 24px;
}

.content__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 24px;

  @media(min-width: $size-tablet) {
    font-size: 36px;
    line-height: 42px;
  }
}

.content__text {
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  max-width: 414px;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

// Main Content
// ================================
.content__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 66.25px;
  padding-top: 2px;

  @media(min-width: $size-tablet-inner) {
    padding-top: 42.5px;
    margin-bottom: 122.5px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 82.5px;
    margin-bottom: 163px;
  }
}

.content__container--inner {
  width: 100%;
  max-width: $size-tablet-inner;
  
  @media(min-width: $size-semi-tablet) {
    max-width: $size-semi-tablet;
  }
}

.content__logo {
  max-height: 56px;
  max-width: 100%;
  object-fit: contain;
  height: auto;
  margin-bottom: 66px;

  @media(min-width: $size-tablet-inner) {
    max-height: 85px;
    height: 85px;
    margin-bottom: 82.5px;
  }
}

.content__line--main {
  width: 64px;
  height: 4px;
  background: linear-gradient(90.11deg, #E95314 -4.59%, #F1A055 100%);
  margin-bottom: 24px;

  @media(min-width: $size-semi-tablet) {
    width: 120px;
  }
}

.content__title--main {
  max-width: 407px;
}

.main__container {
  display: flex;
  flex-direction: column;
  
  @media(min-width: $size-semi-tablet) {
    flex-direction: row;
  }
}

.main__content {
  flex: 1 0 50%;

  & + & {
    padding-left: 0;
    margin-top: 24px;
  }

  @media(min-width: $size-semi-tablet) {
    & + & {
      padding-left: 72px;
      margin-top: 0;
    }
  }
}

.project__intro {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  max-width: 407px;
  margin: 0;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.content__separator {
  margin-bottom: 16px;
}

.content__label {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.content__list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .content__text {
    &::before {
      content: "-";
      padding-right: 8px;
    }
  }
}

.content__link {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: $orange;

  &:hover {
    cursor: pointer;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
  }
}

.content__socmed {
  display: flex;
  margin-top: 26.25px;

  @media(min-width: $size-tablet-inner) {
    margin-top: 85.25px;
  }

  @media(min-width: $size-semi-tablet) {
    margin-top: 85px;
  }
}

.share__label {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  align-self: center;
  margin-right: 25.25px;
  color: $black;

  @media(min-width: $size-tablet-inner) {
    font-weight: 400;
    letter-spacing: 0.03em;
    margin-right: 27.25px;
  }

  @media(min-width: $size-semi-tablet) {
    font-weight: 300;
    margin-right: 27px;
    letter-spacing: 0.02em;
    align-self: flex-start;
  }
}