@import "../../scss/variables/_index.scss";

.details__banner {
  position: relative;
  display: flex;
  flex: 1;
  height: 400px;

  @media(min-width: $size-tablet-inner) {
    height: 450px;
  }
  
  @media(min-width: $size-semi-tablet) {
    height: 100vh;
  }
}

.banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}