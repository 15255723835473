@import "../../scss/variables/_index.scss";

.main__banner {
  position: relative;
  width: 100%;
  padding-top: ( 400 / 375 ) * 100%;

  @media(min-width: $size-tablet-inner) {
    padding-top: ( 450 / 835 ) * 100%;
    height: auto;
  }
  
  @media(min-width: $size-semi-tablet) {
    height: 100vh;
    padding-top: 0;
  }
}

.banner__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.banner__info {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 20px;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-right: 4px solid $orange;
  padding-right: 16px;

  @media(min-width: $size-tablet-inner) {
    right: 36px;
  }

  @media(min-width: $size-semi-tablet) {
    right: 68px;
    height: 240px;
    padding-right: 24px;
  }
}

.banner__icon {
  font-size: 24px;
  line-height: 0;
  margin-right: 16px;

  @media(min-width: $size-semi-tablet) {
    font-size: 56px;
    margin-right: 24px;
  }
}

.service__name {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: $gray0;

  @media(min-width: $size-semi-tablet) {
    font-size: 70px;
    line-height: 1;
  }
}

.service__info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray0;
  padding: 64px 20px;
  padding-bottom: 30px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 64px;
    padding-bottom: 62px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 160px 0x;
    padding-bottom: 160px;
  }
}

.service__info--inner {
  display: flex;
  flex-direction: column;
  max-width: 504px;
  width: 100%;

  @media(min-width: $size-semi-tablet) {
    max-width: 858px;
  }
}

.line {
  width: 64px;
  height: 4px;
  background: linear-gradient(90.11deg, #E95314 -4.59%, #F1A055 100%);
  margin-bottom: 24px;

  @media(min-width: $size-semi-tablet) {
    width: 120px;
  }
}

.service__intro {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: $black;
  max-width: 407px;
  margin-bottom: 24px;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
  }
}

.service__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  @media(min-width: $size-semi-tablet) {
    flex-direction: row;
  }
}

.service__text {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  color: $gray50;
  max-width: 407px;
  margin-bottom: 28px;

  @media(min-width: $size-tablet-inner) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 64px;
  }

  @media(min-width: $size-semi-tablet) {
    margin-bottom: 0;
  }
}

.socmed__title {
  margin-right: 27px;
}

.service__socmed {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}

.socmed {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
