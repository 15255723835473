@import "../../scss/variables/_index.scss";

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: $font-noto;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 40px;
  height: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.arrow-disabled {
  opacity: 0.3;

  &:hover {
    cursor: text;
    opacity: 0.3;
  }
}

.arrow-left {
  margin-right: 24px;
}

.arrow-right {
  margin-left: 24px;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  width: 26px;
  height: 26px;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  & + & {
    margin-left: 24px;
  }

  @media(min-width: $size-tablet-inner) {
    font-size: 16px;
    line-height: 26px;
  }
}

.page__active {
  background: $orange;
}