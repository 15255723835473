$gray0: #ffffff;
$gray10: #f2f2f2;
$gray20: #e0e0e0;
$gray30: #828282;
$gray40: #333333;
$gray50: #262626;
$gray80: #1e1e1e;

$black: #000000;
$gray-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);;

$orange: #e95314;
$orange10: #ff8933;
$orange-opacity: rgba(233, 83, 20, 0.3);

$red: #cc0000;