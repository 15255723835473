$font-noto: "Noto Sans", Verdana, sans-serif;
$font-gabriela: "Gabriela", 'Times New Roman', serif;

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 19, 2021 */

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-thin-webfont.woff2') format('woff2'),
       url('./fonts/notosans-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-extralight-webfont.woff2') format('woff2'),
       url('./fonts/notosans-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-light-webfont.woff2') format('woff2'),
       url('./fonts/notosans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-regular-webfont.woff2') format('woff2'),
       url('./fonts/notosans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-medium-webfont.woff2') format('woff2'),
       url('./fonts/notosans-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-semibold-webfont.woff2') format('woff2'),
       url('./fonts/notosans-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-bold-webfont.woff2') format('woff2'),
       url('./fonts/notosans-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/notosans-black-webfont.woff2') format('woff2'),
       url('./fonts/notosans-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gabriela';
  src: url('./fonts/gabriela_medium-webfont.woff2') format('woff2'),
       url('./fonts/gabriela_medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}