@import "../../scss/variables/_index.scss";

.button {
  display: flex;
  font-family: $font-noto;
  font-size: 12px;
  font-weight: 600;
  line-height: 9px;
  letter-spacing: .2em;
  color: $orange;
  border: 1px solid $orange;
  height: 36px;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 0 16px;

  &:hover {
    cursor: pointer;
    border-color: $orange-opacity;
  }
}

.button--full {
  width: 100%;
}

// Scroll Button
// ==============================================
.button__scroll {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: $gray50;
  bottom: 48px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background .3s ease-in;

  &:hover {
    cursor: pointer;
    background: $black;
  }

  @media(min-width: $size-tablet-inner) {
    bottom: 64px;
    right: 36px;
    width: 64px;
    height: 64px;
  }

  @media(min-width: $size-semi-tablet) {
    display: none;
  }
}

.button-visible {
  display: flex;

  @media(min-width: $size-semi-tablet) {
    display: none;
  }
}

.button__scroll--icon {
  font-size: 16px;
  line-height: 0;

  @media(min-width: $size-tablet-inner) {
    font-size: 25.6px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 32px;
  }
}