@import "../../scss/variables/_index.scss";

.team__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $gray80;
  padding: 64px 0;

  @media(min-width: $size-tablet-inner) {
    padding: 80px 0;
    padding-left: 36px;
  }

  @media(min-width: 1150px) {
    padding-left: 0;
  }
}

.company__intro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 64px;
  background: $gray80;

  @media(min-width: $size-tablet) {
    padding-top: 40px;
    padding-bottom: 120px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 120px;
    padding-bottom: 200px;
  }
}

.intro__inner {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: $size-desktop-inner;
  padding: 0 36px;

  @media(min-width: $size-mid-desktop) {
    padding: 0;
  }
}

.intro__left {
  display: none;
  flex: 2;
  padding-right: 64px;

  @media(min-width: $size-tablet) {
    display: flex;
    flex: 1;
    padding-right: 32px;
  }

  @media(min-width: $size-semi-tablet) {
    flex: 2;
    padding-right: 64px;
  }

  @media(min-width: $size-mid-desktop) {
    padding-right: 0;
  }
}

.intro__image {
  width: 100%;

  @media(min-width: $size-tablet-inner) {
    max-width: 607px;
  }

  @media(min-width: $size-semi-tablet) {
    max-width: 607px;
  }
}

.intro__right {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $gray0;
}

.intro__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 32px;
}

.intro__text {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-bottom: 32px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.button__panel {
  display: flex;
}

