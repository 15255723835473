// Media Sizing
// ===============================================
$size-desktop: 1440px;
$size-mid-desktop: 1150px;
$size-desktop-inner: 1080px;

$size-outer-semi-tablet: 960px;
$size-semi-tablet: 920px;

$size-tablet-outer: 820px;
$size-tablet: 768px;
$size-tablet-inner: 500px;

$size-mobile: 360px;