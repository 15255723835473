@import "../../scss/variables/_index.scss";

.container {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: (706 / 1440) * 100%;
}

.banner__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.banner__image {
  width: 100%;
  height: 100%;
}

.arrows__container {
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: auto;
  width: 100%;
  max-width: $size-desktop;
  z-index: 3;
}

.arrows__container--inner {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  flex-direction: row;
  
  @media(min-width: $size-tablet-inner) {
    right: 36px;
  }

  @media(min-width: $size-semi-tablet) {
    right: 67px;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $orange;
  transition: background-color .3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: $orange10;
  }

  @media(min-width: $size-tablet-inner) {
    width: 64px;
    height: 64px;
  }

  @media(min-width: $size-semi-tablet) {
    width: 80px;
    height: 80px;
  }
}

.arrow-disabled {
  background-color: $gray0;

  &:hover {
    cursor: text;
    background-color: $gray0;
  }
}

.arrow-left {
  background: $orange10;
}

.arrow__icon {
  font-size: 14px;
  line-height: 0;

  @media(min-width: $size-tablet-inner) {
    font-size: 22px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 28px;
  }
}