$icon-size-xs: 12px;
$icon-size-s: 16px;
$icon-size-sm: 20px;
$icon-size-m: 24px;
$icon-size-l: 32px;
$icon-size-xl: 50px;
$icon-size-huge: 200px;

.icon {
  box-sizing: border-box;
  display: inline-block;
  fill: currentColor;
  vertical-align: middle;
  line-height: 0;
}

.icon--xs {
  width: $icon-size-xs;
  height: $icon-size-xs;
  font-size: $icon-size-xs;
}

.icon--s {
  width: $icon-size-s;
  height: $icon-size-s;
  font-size: $icon-size-s;
}

.icon--sm {
  width: $icon-size-sm;
  height: $icon-size-sm;
  font-size: $icon-size-sm;
}

.icon--m {
  width: $icon-size-m;
  height: $icon-size-m;
  font-size: $icon-size-m;
}

.icon--l {
  width: $icon-size-l;
  height: $icon-size-l;
  font-size: $icon-size-l;
}

.icon--xl {
  width: $icon-size-xl;
  height: $icon-size-xl;
  font-size: $icon-size-xl;
}

.icon--huge {
  width: $icon-size-huge;
  height: $icon-size-huge;
  font-size: $icon-size-huge;
}

.icon--mr {
  margin-right: 8px;
}

.icon--ml {
  margin-left: 8px;
}

.icon--not-found {
  position: relative;
  border: 1px dashed #f00;
  border-radius: 50%;
  animation: blinking 0.5s linear 0.2s infinite alternate;

  &::before {
    content: "";
    display: block;
    width: 80%;
    height: 10%;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #f00;
    transform: translate(-50%, -50%);
  }
}

@keyframes blinking {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}
