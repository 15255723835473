@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media(min-width: $size-tablet) {
    margin-bottom: 32px;
    overflow-x: auto;
    max-width: $size-tablet;
  }
  @media(max-width: $size-tablet-inner) {
    margin-bottom: 32px;
    overflow-x: auto;
    max-width: $size-mobile;
    padding: 0 1em;
  }
  @media(max-width: $size-mobile) {
    max-width:360px;
    padding: 0 1em;
  }
}

.category__link {
  flex: 0 0 auto;
  padding: 8px 0;
  cursor: pointer;

  & + & {
    margin-left: 45px;
  }

  @media(min-width: $size-tablet) {
    & + & {
      margin-left: 80px;
    }
  }
}

.link-active {
  border-bottom: 2px solid $orange;

  >.category__name {
    font-weight: 700;
    color: $orange;
  }
}

.category__name {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: $gray50;

  @media(min-witdh: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}