@import "~normalize.css/normalize";
@import "variables/index.scss";

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
//Page 
// ===================================
.page__container {
  display: flex;
  padding-top: 64px;
  background: $gray80;
  justify-content: center;

  @media(min-width: $size-tablet-inner) {
    padding-top: 72px;
  }

  @media(min-width: $size-tablet-outer) {
    padding-top: 130px;
  }
}

//Slider
// ===================================
#page-slider {
  .slick-slider {
    height: 100% !important;
  
    * {
      height: 100% !important;
    }
  }
}

// Searchable select
// ===================================
.searchable-select__container {
  width: 100%;
}

.searchable-select__control {
  border: 1px solid $orange !important;
  border-radius: 0 !important;
  padding: 0 16px !important;
  height: 39px;

  @media(min-width: $size-semi-tablet) {
    height: 42px;
  }
}

.searchable-select__value-container {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  color: $orange;
  padding: 0 !important;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.searchable-select__placeholder {
  color: $orange !important;
}

.searchable-select__single-value {
  color: $orange !important;
}

.searchable-select__indicator-separator {
  display: none;
}

.searchable-select__indicator {
  color: $orange !important;
  padding: 0 !important;
}

.searchable-select__menu {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  background: $gray10 !important;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}