@import "../../scss/variables/_index.scss";

.details__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: $gray0;
  padding: 64px 20px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 36px;
  }
  
  @media(min-width: $size-semi-tablet) {
    padding: 80px 67.5px;
  }
}

.details__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  max-width: $size-desktop;
}

.content__mb80 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.content__m80 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}

.content__mt80 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.container__map {
  padding-top: 64px;
}