@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $gray40;
  
  & + & {
    margin-top: 24px;
  }

  @media(min-width: $size-tablet) {
    flex-direction: row;

    & + & {
      margin-top: 40px;
    }
  }
}

.service__info {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  color: $gray0;
  padding: 32px 40px;

  @media(min-width: $size-tablet) {
    padding-right: 53px;
    width: calc(100% - 224px);
  }

  @media(min-width: $size-semi-tablet) {
    padding-right: 70px;
    width: calc(100% - 334px);
  }
}

.service__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  @media(min-width: $size-tablet) {
    margin-bottom: 29px;
  }
}

.service__icon {
  font-size: 24px;
  line-height: 0;
  margin-right: 16px;
  align-self: center;

  @media(min-width: $size-tablet) {
    font-size: 32px;
  }
}

.service__name {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  align-self: center;
  margin: 0;

  @media(min-width: $size-tablet) {
    font-size: 36px;
    line-height: 42px;
  }
}

.service__caption {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.03em;
  margin-bottom: 24px;
}

.service__pattern {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 108px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.164);

  @media(min-width: $size-tablet) {
    width: 224px;
    height: auto;
  }

  @media(min-width: $size-semi-tablet) {
    width: 334px;
  }
}

.button__panel {
  display: flex;
}