@import "../../scss/variables/_index.scss";

.container {
  position: relative;
}

.banner {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.banner__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
}

.banner__inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: $size-desktop;
}

.banner__paging {
  display: flex;
  position: absolute;
  bottom: 24px;
  left: 20px;

  @media(min-width: $size-tablet-inner) {
    bottom: 32px;
    left: auto;
    right: 36px;
  }

  @media(min-width: $size-semi-tablet) {
    bottom: 40px;
    right: 67px;
  }
}

.banner__props {
  display: flex;
  position: absolute;
  top: 40%;
  padding: 0 20px;
  align-items: flex-start;

  @media(min-width: $size-semi-tablet) {
    padding: 0 36px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-left: 60px;
  }
}

.banner__count {
  display: none;
  font-family: $font-gabriela;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.2em;
  padding-top: 16px;
  color: $gray0;

  @media(min-width: $size-tablet) {
    display: block;
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 32px;
  }
}

.banner__count--separator {
  height: 1px;
  width: 76px;
  background: $gray0;
}

.banner__props--right {
  max-width: 615px;
  padding-left: 0;

  @media(min-width: $size-tablet) {
    padding-left: 36px;
  }
}

.banner__props--title {
  font-family: $font-gabriela;
  font-size: 36px;
  line-height: 40px;
  color: $gray0;
  margin: 0;
  margin-bottom: 16px;

  @media(min-width: $size-tablet) {
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 32px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 70px;
    line-height: 70px;
  }
}

.banner__link {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: $gray0;
  letter-spacing: 0.2em;
}

// Banner Pagination
// ========================================
.banner__container--paging {
  max-width: 64px;
  width: 100%;
  padding-right: 38px;
  padding-bottom: 8px;
  border-bottom: 2px solid $orange;
  color: $orange;

  &:hover {
    cursor: pointer;
  }

  @media(min-width: $size-tablet-inner) {
    padding-bottom: 16px;
    max-width: 76px;
  }

  @media(min-width: $size-tablet-outer) {
    max-width: 211px;
  }
}

.banner__container-active {
  border-color: $gray0;
  color: $gray0;
}

.banner__number {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  letter-spacing: 0.2em;

  @media(min-width: $size-semi-tablet) {
    font-size: 16px;
    line-height: 22px;
  }
}

.banner__title {
  display: none;
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;

  @media(min-width: $size-semi-tablet) {
    display: block;
  }
}