@import "../../scss/variables/_index.scss";

.modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10;
}

.modal-open {
  display: flex;
}

.modal__inner {
  display: flex;
  position: relative;
  padding: 0 20px;
  justify-content: center;

  @media(min-width: $size-tablet) {
    padding: 0;
    width: auto;
  }
}

.modal__close {
  position: absolute;
  top: 17.2px;
  right: 37.2px;

  &:hover {
    cursor: pointer;
  }

  @media(min-width: $size-tablet) {
    top: -46.4px;
    right: -46.4px;
  }

  @media(min-width: $size-semi-tablet) {
    top: -54px;
    right: -54.5px;
  }
}

.modal__close--icon {
  font-size: 21.6px;

  @media(min-width: $size-tablet) {
    font-size: 28.8px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 36px;
  }
}

// Content 
// =============================================
.modal__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 335px;
  max-height: 90vh;
  
  @media(min-width: $size-tablet) {
    max-width: 376px;
  }

  @media(min-width: $size-semi-tablet) {
    flex-direction: row;
    max-width: 707px;
  }

  @media (max-width: $size-semi-tablet - 1) {
    overflow: auto;
  }
}

.modal__image {
  width: 100%;
  min-width: auto;
  object-fit: cover;

  @media(min-width: $size-tablet) {
    min-width: 335px;
  }
}

.modal__info {
  background: $gray10;
  padding: 32px 40px;
  color: $black;

  @media(min-width: $size-semi-tablet) {
    padding: 40px;
  }
}

.modal__name {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 8px;

  @media(min-width: $size-tablet) {
    font-size: 36px;
    line-height: 42px;
  } 

  @media(min-width: $size-semi-tablet) {
    font-size: 28px;
    line-height: 33px;
  }
}

.modal__position {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.02em;
  margin-bottom: 10px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 8px;
  }
}

.modal__text {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  letter-spacing: 0.03em;
  margin: 0;
  overflow-y: scroll;
  max-height: 280px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media(min-width: $size-tablet) {
    max-height: 300px;
  }
}