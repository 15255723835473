@import "../../scss/variables/_index.scss";

.team__intro {
  display: flex;
  flex: 1;
  justify-content: center;
  background: $gray80;
  color: $gray0;
  padding: 64px 20px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 36.5px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 200px 36.5px;
  }

  @media(min-width: $size-mid-desktop) {
    padding: 200px 0;
  }
}

.team__inner {
  display: flex;
  flex: 1;
  max-width: $size-desktop-inner;
}

.team__container--image {
  display: none;

  @media(min-width: $size-tablet) {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  @media(min-width: $size-semi-tablet) {
    flex: 2;
  }
}

.team__image {
  width: 100%;
  // height: 100%;
  &__container {
    position: relative;
    &:before {
      content: '';
      width: 80px;
      height: 4px;
      background: linear-gradient(90.11deg, #E95314 -4.59%, #F1A055 100%);
      position: absolute;
      top: 24px;
      left: 24px;
    }
  }

  @media(min-width: $size-tablet) {
    max-width: 373px;
  }

  @media(min-width: $size-semi-tablet) {
    max-width: 607px;
  }
}

.team__info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  @media(min-width: $size-tablet) {
    padding-left: 64px;
  }
}

.team__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 32px;
}

.team__caption {
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin-bottom: 32px;
}

.button__panel {
  display: flex;
}

