@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: $size-desktop;
  padding: 40px 20px;
  color: $gray0;
  background: $gray80;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 36px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 200px 67px;
  }
}

.caption {
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.caption__title {
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.keyword {
  font-family: $font-gabriela;
  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  margin: 0;
  margin-bottom: 40px;

  @media(min-width: $size-tablet) {
    font-size: 36px;
    line-height: 42px;
  }
}

.search__results {
  max-width: 859px;
}