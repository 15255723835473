@import "../../scss/variables/_index.scss";

.contact__page--container {
  padding: 0 20px;

  @media(min-width: $size-outer-semi-tablet) {
    padding: 0 32px;
  }
}

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: $size-desktop;
  padding-top: 104px;
  padding-bottom: 64px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 0; 
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 210px;
    padding-bottom: 200px;
  }
}

.contact__container--image {
  position: relative;
  display: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: (635 / 800) * 100%;

  @media(min-width: $size-outer-semi-tablet) {
    display: flex;
  }
}

.contact__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.container__form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media(min-width: $size-outer-semi-tablet) {
    padding: 0 32px;
  }
}

.form__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 503px;
}

.contact__title {
  font-family: $font-gabriela;
  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  color: $gray0;
  margin: 0;
  margin-bottom: 24px;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
  }
}

.contact__caption {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  color: $gray0;
  letter-spacing: 0.02em;
  margin: 0;

  @media(min-width: $size-outer-semi-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.form__wrapper {
  padding: 33px 0;
  max-width: 503px;
  width: 100%;

  @media(min-width: $size-tablet-outer) {
    max-width: 411px;
  }
}

.captcha__container {
  margin-bottom: 33px;
}

.captcha__message {
  font-family: $font-noto;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.03;
  color: $orange;
  margin-top: 8px;
}

// Form Status
// ==========================================
.status__container {
  background: $gray10;
  padding: 32px 39px;
  margin-top: 40px;
}

.status__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: $orange;
  margin-bottom: 32px;
}

.status__content {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  color: $gray50;
  margin: 32px 0;
}

.status__number {
  font-weight: 700;
}