@import "../../scss/variables/_index.scss";

.projects__content {
  display: flex;
  justify-content: center;
  background: $gray0;
  padding: 48px 20px;
  padding-bottom: 64px;

  @media(min-width: $size-tablet) {
    padding: 48px 36px;
    padding-bottom: 120px;
  }

  @media(min-width: $size-desktop-inner) {
    padding: 80px 67px;
    padding-bottom: 200px;
  }
}

.projects__inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  max-width: $size-desktop;
}

.project__list {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  
  @media(min-width: $size-tablet-inner) {
    width: calc(100% + 15.5px);
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: $size-tablet) {
    grid-template-columns: repeat(3, 1fr);

  }
  
  @media(min-width: $size-desktop-inner) {
    width: calc(100% + 40px);
  }
}

.project__link {
  // flex: 1 0 100%;
  margin-bottom: 40px;
  margin-right: 0;
  display: block;
  max-width: 100%;

  &:hover {
    .project__card {
      opacity: 0.6;
    }
  }

  @media(min-width: $size-tablet-inner) {
    // flex: 1 0 calc(50% - 31px);
    margin-right: 15.5px;
  }

  @media(min-width: $size-tablet) {
    // flex: 1 0 calc(33.3% - 31px);
  }

  @media(min-width: $size-desktop-inner) {
    /*flex: 1 0 calc(25% - 80px); */
    // flex: 1 0 calc(25%); 
    margin-right: 40px;
    margin-bottom: 32px;
  }
}

.project__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project__thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 18px;
}

.project__logo {
  height: 56px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  align-self: flex-start;
  margin-bottom: 18px;

  @media(min-width: $size-tablet) {
    height: 40px;
  }

  @media(min-width: $size-desktop-inner) {
    height: 56px;
  }
}

.project__category {
  font-family: $font-noto;
  font-size: 16px;
  line-height: 23px;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: $gray50;
}

.button__panel {
  display: flex;
  width: 100%;
  align-self: center;

  @media(min-width: $size-tablet) {
    width: 335px;
  }
}