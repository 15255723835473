@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $gray80;
  z-index: 8;
  transform: translateX(100vw);
  transition: transform .5s ease;

  @media(min-width: $size-outer-semi-tablet) {
    display: none;
  }
}

.container__inner {
  max-height: 100vh;
  overflow: scroll;
  padding: 80px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu-show {
  transform: translateX(0);
}

.menu__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;

  @media(min-width: $size-tablet-inner) {
    margin-bottom: 113px;
  }
}

.menu__link {
  display: flex;
  flex: 1;
  font-family: $font-noto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: $gray0;
  padding: 0 48px;

  & + & {
    margin-top: 32px;
  }

  @media(min-width: $size-mobile) {
    font-size: 26px;
  }

  @media(min-width: $size-tablet-inner) {
    font-size: 30px;

    & + & {
      margin-top: 48px;
    }
  }
}

.menu__search {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 220px;
  margin-bottom: 74.25px;

  @media(min-width: $size-tablet-inner) {
    margin-bottom: 123px;
    max-width: $size-tablet-inner;
    padding: 0 20px;
  }
}

.menu__search--input {
  width: 100%;
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 300;
  color: $gray0;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid $gray0;
  padding: 8px 0;

  &::-webkit-input-placeholder { /* Edge */
    color: $gray0;
  }

  &::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $gray0;
  }
  
  &::placeholder {
    color: $gray0;
  }
}

.menu__search--button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  align-self: center;
}

.menu__icon {
  font-size: 24px;
  align-self: center;
}

.socmed__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu__socmed--link {
  & + & {
    margin-left: 17.5px;
  }
}

.menu__socmed--icon {
  font-size: 30px;
  color: $gray0;
}