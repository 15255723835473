@import "../../scss/variables/_index.scss";

.container {
  max-width: $size-desktop;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container__banner {
  padding: 0 20px;
  height: 335px;

  @media(min-width: $size-tablet-inner) {
    height: 508px;
  }

  @media(min-width: $size-tablet) {
    padding: 0 36px;
  }

  @media(min-width: $size-semi-tablet) {
    height: 640px;
  }

  @media(min-width: $size-desktop-inner) {
    padding: 0 67px;
  }
}

.banner {
  max-width: 1306px;
  height: 335px;

  @media(min-width: $size-tablet-inner) {
    max-width: 1306px;
    height: 508px;
  }

  @media(min-width: $size-semi-tablet) {
    height: 640px;
  }
}

.banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container__inner {
  position: relative;
  display: flex;
  padding: 0 20px;

  @media(min-width: $size-tablet) {
    padding: 0;
  }
}

.arrow__container {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  top: -40px;
  right: 20px;

  @media(min-width: $size-tablet) {
    position: relative;
    top: auto;
    right: auto;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: $orange;
  
  &:hover {
    cursor: pointer;
  }

  @media(min-width: $size-tablet) {
    width: 64px;
    height: 64px;
  }

  @media(min-width: $size-tablet-outer) {
    width: 80px;
    height: 80px;
  }
}

.arrow--left {
  background: $orange10;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
}

.arrow__icon {
  font-size: 16px;
  cursor: pointer;
  @media(min-width: $size-tablet) {
    font-size: 24px;
  }
}

.section__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 36px;
  background: $gray10;

  @media(min-width: $size-tablet) {
    margin-top: -140px;
    padding: 62px 0;
    padding-left: 75px;
    padding-right: 69px;
  }
}

.section__title {
  font-family: $font-gabriela;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  margin: 0;
  margin-bottom: 32px;
}

.section__text {
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-bottom: 32px;
}

.button__panel {
  display: flex;
}