@import "../../scss/variables/_index.scss";

.container {
  margin-top: 64px;
}

.line__separator {
  width: 64px;
  height: 4px;
  background: linear-gradient(90.11deg, #E95314 -4.59%, #F1A055 100%);
  margin-bottom: 24px;
}

.map__title {
  font-family: $font-gabriela;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 24px;
}

.map__container {
  position: relative;
  display: flex;
  height: 503px;
}

// Info Box
// ======================================
.info__container {
  width: 150px;
  padding: 4px;
  border-radius: 8px;
  background: $gray0;
}

.info__name {
  font-weight: 700;
}