@import "../../scss/variables/_index.scss";

.services__intro {
  display: flex;
  flex: 1;
  justify-content: center;
  color: $gray0;
  background-color: $gray50;
  background-image: url("/assets/patterns/pattern03.svg");
  background-repeat: repeat;
  background-size: 150px;
  padding: 64px 20px;
  padding-top: 104px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 36px;
    padding-top: 240px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 310px;
    padding-bottom: 200px;
  }
}

.services__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $size-desktop-inner;

  @media(min-width: $size-tablet-inner) {
    max-width: 500px;
  }

  @media(min-width: $size-semi-tablet) {
    max-width: $size-desktop-inner;
  }
}

.services__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 32px;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
  }
}

.services__caption {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  max-width: 523px;

  @media(min-width: $size-tablet-inner) {
    font-size: 16px;
    line-height: 26px;
  }
}

.service__container--banner {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: (211 / 375) * 100%;
  overflow: hidden;

  @media(min-width: $size-tablet) {
    padding-top: (409 / 835) * 100%;
  }
  
  @media(min-width: $size-semi-tablet) {
    padding-top: (708 / 1440) * 100%;
  }
}

.service__banner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.services__container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 65px;
  background: $gray80;

  @media(min-width: $size-tablet) {
    padding-top: 80px;
    padding-bottom: 200px;
  }
}

.services__types {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20.5px;

  @media(min-width: $size-tablet-inner) {
    padding: 0 36.5px;
  }

  @media(min-width: $size-desktop-inner) {
    max-width: $size-desktop-inner;
  }
}