@import "../../scss/variables/_index.scss";

.result__link {
  font-family: $font-noto;

  &:hover {
    > .container {
      border-bottom-color: $gray0;

      > .breadcrumbs {
        color: $gray0;
      }
    }
  }
}

.container {
  border-bottom: 1px solid $gray30;
  padding-bottom: 16px;
  margin-bottom: 40px;
}

.pageName {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: $gray0;
  margin-bottom: 16px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: $gray30;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.separator {
  margin: 0 8px;
}

