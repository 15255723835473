@import "../../scss/variables/_index.scss";

.section {
  display: flex;
  justify-content: center;
  padding: 0 20px;

  @media(min-width: $size-tablet-inner) {
    padding: 0 32px;
  }
}

.section--black {
  background-color: $black;
}

.section__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: $size-desktop-inner;
  padding: 64px 0;

  @media(min-width: $size-tablet-inner) {
    padding: 80px 0;
  }

  @media(min-width: $size-semi-tablet) {
    flex-direction: row;
  }
}

.section--design {
  @media(min-width: $size-semi-tablet) {
    padding: 160px 0;
  }
}

.section__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;

  @media(min-width: $size-desktop-inner) {
    margin-bottom: 0;
  }
}

.section__right {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.section__container--image {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 40px;

  @media(min-width: $size-tablet-inner) {
    padding-top: 0;
  }
}

.button__panel {
  display: flex;
}

// Career Section
// ==============================================
.career__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 0;

  @media(min-width: $size-tablet-inner) {
    padding: 80px 0;
  }

  @media(min-width: $size-semi-tablet) {
    flex-direction: row;
  }
}

.career-left {
  @media(min-width: $size-semi-tablet) {
    padding-right: 16px;
  }
}

.career-right {
  @media(min-width: $size-semi-tablet) {
    padding-left: 16px;
  }
}

.career__title {
  font-family: $font-gabriela;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  color: $gray0;
  padding: 0;
  margin: 0;
  text-align: right;

  @media(min-width: $size-tablet) {
    font-size: 55px;
    line-height: 55px;
  }

  @media(min-width: $size-desktop-inner) {
    font-size: 70px;
    line-height: 70px;
  }
}

.career__caption {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: $gray0;
  margin: 0;
  max-width: 500px;
  margin-bottom: 32px;

  @media(min-width: $size-desktop-inner) {
    font-size: 16px;
    line-height: 26px;
  }
}

// Design Section
// ==============================================
.design-left {
  padding-right: 0;

  @media(min-width: $size-semi-tablet) {
    padding-right: 16px;
  }
}

.design-right {
  padding-left: 0;

  @media(min-width: $size-semi-tablet) {
    padding-left: 16px;
  }
}

.design__title {
  font-family: $font-gabriela;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  color: $gray0;
  margin: 0;
  margin-bottom: 32px;

  @media(min-width: $size-tablet-outer) {
    margin-bottom: 36px;
  }
}

.design__caption {
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: $gray0;
  margin-bottom: 36px;
}

.design__img {
  display: flex;
  width: 100%;
  align-self: center;
}

// Services Section
// ==============================================
.services__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 20px;

  @media(min-width: $size-tablet) {
    flex-direction: row;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 200px 36px;
  }
}

.services {
  position: relative;
  width: 100%;
  height: 180px;
  padding-right: 0;
  transition: padding .3s ease-out;

  &:hover {
    padding-right: 0;
  }

  & + & {
    margin-top: 24px;
  }

  @media(min-width: $size-tablet) {
    width: 259px;
    height: 244px;
    padding-right: 15px;

    & + & {
      margin-top: 0;
    }
  }

  @media(min-width: $size-semi-tablet) {
    width: 447px;
    height: 411px;
    padding-right: 36px;
  }
}

.services--mid {
  &:hover {
    padding-left: 0;
    padding-right: 0;
  }

  @media(min-width: $size-tablet) {
    width: 274px;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: -15px;
  }

  @media(min-width: $size-semi-tablet) {
    width: 483px;
    padding-left: 36px;
    margin-left: -36px;
    padding-right: 36px;
    margin-right: 0;
  }
}

.services--last {
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;

  &:hover {
    padding-left: 0;
  }

  @media(min-width: $size-tablet) {
    padding-left: 15px;
    margin-left: -15px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-left: 36px;
    margin-left: -36px;
  }
}

.services--inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.services__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service__image--overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.services__info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media(min-width: $size-tablet) {
    width: 244px;
    height: 244px;
  }

  @media(min-width: $size-semi-tablet) {
    width: 100%;
    height: 411px;
  }
}

.services__info--last {
  left: auto;
  right: 0;
}

.services__info--inner {
  position: absolute;
  bottom: 0;
  right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 0 16px;
  border-right: 2px solid $orange;

  @media(min-width: $size-tablet-inner) {
    height: 56px;
    right: 24px;
  }

  @media(min-width: $size-semi-tablet) {
    height: 83px;
    right: 32px;
  }
}

.services__icon {
  font-size: 24px;
  margin-right: 8px;

  @media(min-width: $size-semi-tablet) {
    margin-right: 16px;
  }
}

.services__title {
  font-family: $font-gabriela;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $gray0;
  margin: 0;

  @media(min-width: $size-tablet-inner) {
    font-size: 18px;
    line-height: 18px;
  }

  @media(min-width: $size-semi-tablet) {
    font-size: 24px;
    line-height: 24px;
  }
}

// Section Intro
// =======================================
.section__intro {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  color: $gray0;
  background-color: $gray50;
  padding: 64px 20px;
  padding-top: 104px;

  @media(min-width: $size-tablet-inner) {
    padding: 120px 36px;
    padding-top: 240px;
  }

  @media(min-width: $size-semi-tablet) {
    padding-top: 310px;
    padding-bottom: 200px;
  }
}

.intro__inner {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $size-desktop-inner;

  @media(min-width: $size-tablet-inner) {
    max-width: 500px;
  }

  @media(min-width: $size-semi-tablet) {
    max-width: $size-desktop-inner;
  }
}

.intro__title {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 32px;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
  }
}

.intro__caption {
  font-family: $font-noto;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  max-width: 523px;

  @media(min-width: $size-tablet-inner) {
    font-size: 16px;
    line-height: 26px;
  }
}