@import "../../scss/variables/_index.scss";

.share {
  & + & {
    margin-left: 22px;
  }
}

.share__icon {
  font-size: 25.5px;
  line-height: 0;

  @media(min-width: $size-semi-tablet) {
    font-size: 34px;
  }
}