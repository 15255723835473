@import "../../scss/variables/_index.scss";

.client__content {
  display: flex;
  justify-content: center;
  background: $gray0;
  padding: 48px 20px;
  padding-bottom: 64px;

  @media(min-width: $size-tablet) {
    padding: 48px 36px;
    padding-bottom: 120px;
  }

  @media(min-width: $size-desktop-inner) {
    padding: 80px 67px;
    padding-bottom: 200px;
  }
}

.client__inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  max-width: $size-desktop;
}

.client__list {
  display: flex;
  flex-wrap: wrap;
  // align-items: flex-start;
  // justify-content: space-evenly;
  margin-bottom: 64px;
}

.client__link {
  flex: 1 0 50%;
  justify-content: center;
  align-items: center;
  height: 120px;

  &:hover {
    .client__overlay {
      opacity: 0;
    }
  }

  @media(min-width: $size-tablet) {
    flex: 1 0 33%;
    height: 186px;
  }

  @media(min-width: $size-desktop-inner) {
    flex: 1 0 25%;
    max-width: 25%;
    height: 230px;
  }
}

.client__card {
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  min-width: none;
  
  @media(min-width: $size-tablet) {
    min-width: 150px;
  }

  @media(min-width: $size-semi-tablet) {
    min-width: 200px;
  }
}

.client__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255,255,255,0.5);
  transition: opacity .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.client__logo {
  width: 100%;
  max-width: 141px;
  max-height: 42px;
  object-fit: contain;

  @media(min-width: $size-tablet) {
    max-width: 160px;
    max-height: 57px;
  }

  @media(min-width: $size-semi-tablet) {
    max-width: 232px;
    max-height: 76px;
  }
}

.button__panel {
  display: flex;
  width: 100%;
  align-self: center;

  @media(min-width: $size-tablet) {
    width: 335px;
  }
}