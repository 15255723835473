@import "../../scss/variables/_index.scss";

.footer__desktop {
  display: none;

  @media(min-width: $size-tablet) {
    display: block;
  }
}

.footer {
  display: flex;
  justify-content: center;
  font-family: $font-noto;
  background-color: $orange;
}

.footer__container {
  max-width: $size-desktop;
  width: 100%;
  padding: 65px 20px;

  @media(min-width: $size-tablet) {
    padding: 65px 36px;
  }

  @media(min-width: $size-desktop) {
    padding: 65px;
  }
}

.footer__container--section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & + & {
    margin-top: 20px;
  }
}

.footer__container--logo {
  flex: 1;
}

.footer--logo {
  width: 123px;
  height: 102px;
}

.footer__container--link {
  flex: 4;
  text-align: right;
}

.footer__mbl--link {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
}

.footer__link {
  flex: 1;
  color: $black;
  text-decoration: none;

  &:hover {
    color: $gray0;
  }

  & + & {
    margin-left: 0;
    margin-top: 8px;
  }

  @media(min-width: $size-tablet) {
    & + & {
      margin-left: 29px;
    }
  }

  @media(min-width: $size-desktop-inner) {
    & + & {
      margin-left: 61px;
    }
  }

  > span {
    font-family: $font-noto;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
  }
}

.footer__container--address {
  flex: 2;
  font-family: $font-noto;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.footer__address--mbl {
  flex: 1;
  margin-top: 32px;
}

.footer__socmed--title {
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.03em;
}

.footer__socmed--container {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10.25px;
}

.footer__socmed--link {
  & + & {
    margin-left: 19px;
  }
}

.footer__socmed--icon {
  font-size: 30px;
}

.footer__separator {
  flex: 1;
  height: 1px;
  background-color: $black;
  margin-top: 24px;
  margin-bottom: 32px;
}

.copyright {
  font-family: $font-noto;
  font-size: 10px;
}