@import "../../scss/variables/_index.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: $size-desktop;
  padding: 40px 20px;
  color: $gray0;
  margin: 80px 67px 200px 67px;
  background-repeat: no-repeat;
  background-size: auto;
  height: 686px;
  background-image: url("/assets/img404.png");
  
  
  @media(min-width: $size-mobile) {
    padding: 40px 20px;
    margin: 80px 20px 100px 20px;
  }

  @media(min-width: $size-tablet-inner) {
    padding: 260px 67px;
    margin: 80px 67px 200px 67px;
  }

  @media(min-width: $size-semi-tablet) {
    padding: 260px 67px;
    margin: 80px 67px 200px 67px;
  }
  @media(min-width: $size-desktop) {
    max-width: $size-desktop;
    padding: 260px 67px;
    margin: 80px 67px 200px 67px;
    background-repeat: no-repeat;
    background-size: auto;
    height: 686px;
  }
  
}

.caption {
  font-family: $font-gabriela;
  font-size: 70px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 24px;
  width: 456px;
  
  @media(min-width: $size-mobile) {
    font-size: 50px;
    line-height: 45px;
    width:auto;
  }
  
  @media(min-width: $size-tablet) {
    font-size: 70px;
    line-height: 70px;
    width: 456px;
  }
  @media(min-width: $size-desktop) {
    font-size: 70px;
    font-weight: 500;
    line-height: 70px;
    margin-bottom: 24px;
    width: 456px;
  }
}

.caption__title {
  font-family: $font-noto;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.keyword {
  font-family: $font-noto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  margin-bottom: 40px;

  @media(min-width: $size-tablet) {
    font-size: 16px;
    line-height: 26px;
  }
}

.button__panel {
  display: flex;
}