@import "../../scss/variables/_index.scss";

.team__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: $size-desktop-inner;
  position: relative;
  z-index: 1;

  & + & {
    margin-top: 64px;
  }
}

.team__upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.team__name {
  font-family: $font-gabriela;
  font-size: 28px;
  line-height: 33px;
  color: $gray0;
  margin-bottom: 32px;
  padding: 0 20px;

  @media(min-width: $size-tablet-inner) {
    font-size: 36px;
    line-height: 42px;
    padding: 0;
  }
}

.team__list {
  display: flex;
  padding: 0 20px;

  @media(min-width: $size-tablet-inner) {
    padding: 0;
  }
}

.team__list--scroll {
  display: flex;
  overflow-x: auto;
  padding-left: 20px;

  &::-webkit-scrollbar { 
    display: none;
  }

  > .member__card {
    flex-shrink: 0;
  }

  @media(min-width: $size-tablet-inner) {
    padding-left: 0;
  }
}

// Slider
// ==================================
.slider__arrow {
  display: flex;
  flex-direction: row;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;

  & + & {
    margin-left: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.arrow-disabled {
  opacity: .3;

  &:hover {
    cursor: text;
  }
}

.arrow__icon {
  font-size: 24px;
}

// Member Card 
// ==================================
.member__card {
  display: flex;
  position: relative;
  width: 168px !important;
  height: 251px;

  & + & {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
  }

  @media(min-width: $size-tablet-inner) {
    width: 190px !important;
    height: 280px;

    & + & {
      margin-left: 24px;
    }
  }

  @media(min-width: 1150px) {
    width: 188px !important;

    & + & {
      margin-left: 36px;
    }
  }
}

.member__photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 64.06%, #000000 100%);
}

.member__info {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 16px;
  font-family: $font-noto;
  font-size: 16px;
  line-height: 26px;
  color: $gray0;
}

.member__name {
  font-weight: 700;
}

.member__position {
  font-weight: 300;
}